.img{
    object-fit: cover;
}

.rectangle {
    position: relative;
    width: 270px;
    height: 60px;
    border: 2px solid #efeff0;
    overflow: hidden;
}

.inner-rounded {
    position: absolute;
    top: 0;
    left: 0;
    width: 75px; /* Adjust as needed */
    height: 100%;
    background-color: #efeff0; /* Matches background of the container */
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    z-index: 1;
}

.icon {
    position: absolute;
    top: 50%;
    left: 0px; /* Adjust as needed */
    transform: translateY(-50%);
    z-index: 2;
    font-size: 24px; /* Adjust size as needed */
    color: black; /* Icon color */
    width: 20%;
}
.text{
        position: absolute;
        left: 85px;
        z-Index: 2;
        color: #adb6d3;
        font-size: 16px;
        font-weight: bold;
}


@media (max-width: 480px) {
    .hh{
        font-size: 0.2rem !important;
    }
  }