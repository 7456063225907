.mission{
    background-color: #efeff0;
}

h1{
    font-size: 4rem;
    color: #e1c283;
}

@media (max-width: 480px) {
    h1{
        font-size: 2rem;
    }
    p{
        font-size: 1rem;
    }
  }